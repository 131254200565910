import React, { useState } from 'react';
import onion from './certificates/onion.jpg';
import tomato from './certificates/tomato.jpg';
import plum from './certificates/plum.jpg';
import orange from './certificates/orange.jpg';
import lemon from './certificates/lemon.jpg';
import cherries from './certificates/cherries.jpg';
import melon from './certificates/melon.jpg';
import pineapple from './certificates/pineapple.jpg';
import grape from './certificates/grape.jpg';
import peach from './certificates/peach.jpg';
import kiwi from './certificates/kiwi.jpg';
import watermelon from './certificates/watermelon.jpg';
import pear from './certificates/pear.jpg';
import raspberry from './certificates/raspberry.jpg';
import strawberry from './certificates/strawberry.jpg';
import apple from './certificates/apple.jpg';
import banana from './certificates/banana.jpg';


import f1 from './assets/fruits/1.png';
import f2 from './assets/fruits/2.png';
import f3 from './assets/fruits/3.png';
import f4 from './assets/fruits/4.png';
import f5 from './assets/fruits/5.png';

import {
	FacebookShareButton,
	FacebookIcon,
} from "react-share";
import Hello from './components/Hello';
// import ReactGA from 'react-ga';
import Sky from 'react-sky';
import Confetti from 'react-dom-confetti';

function theImageUrl(url) {
	return window.location.protocol + '//' + window.location.hostname + url;
}

// window.gaTracked = false;
// function gaTrack() {
// 	if (window.gaTracked) {
// 		return;
// 	}
// 	ReactGA.initialize('G-0NB1V5BHNE');
// 	ReactGA.pageview(window.location.pathname + window.location.search);
// 	window.gaTracked = true;
// }
window.redraw = 0;
export default function App() {
	// gaTrack();
	// 

	const questions = [
		{
			questionText: 'How many emails do you send in a week?',
			notShuffle: true,
			answerOptions: [
				{ answerText: '< 100', points: 1 },
				{ answerText: '< 1000', points: 2 },
				{ answerText: '< 10000', points: 3 },
				{ answerText: '> 10000', points: 4 },
			],
		},
		{
			questionText: 'Which social platform is your favorite?',
			answerOptions: [
				{ answerText: 'Facebook', points: 1 },
				{ answerText: 'Linkedin', points: 1 },
				{ answerText: 'TikTok', points: 1 },
				{ answerText: 'Instagram', points: 1 },
				{ answerText: 'None is my favourite', points: 2, alwaysLast: true },
			],
		},
		{
			questionText: 'You use phone for:',
			notShuffle: true,
			answerOptions: [
				{ answerText: 'Warm calls', points: 1 },
				{ answerText: 'Cold calls', points: 2 },
				{ answerText: 'Both', points: 4 },
				{ answerText: 'none', points: 0 },
			],
		},
		{
			questionText: 'You write offers:',
			answerOptions: [
				{ answerText: 'Each day even for practice', points: 4 },
				{ answerText: 'Only if I must', points: 2 },
				{ answerText: 'I prefer copywritters', points: 2 },
				{ answerText: 'I do not do that', points: 0 },
			],
		},
		{
			questionText: 'Creating creative content:',
			answerOptions: [
				{ answerText: 'I\'ve got the vision and designer do the rest', points: 2 },
				{ answerText: 'I know exactly what need to be done', points: 4 },
				{ answerText: 'I left it for the designer', points: 0 },
			],
		},
	];

	const scores = {
		2: { certificate: onion, name: 'onion', description: 'Onion is not a fruit. Exactly as you are not a marketter... yet. You need some practice.' },
		3: { certificate: tomato, name: 'tomato', description: 'Not everyone knows that it\'s a fruit. Like it\'s hard to know if you are good at what you do. Practice more.' },
		4: { certificate: plum, name: 'plum', description: 'It\'s highly likely that plum will have a worm inside. Like it\'s likely that yor marketing isn\'t working yet.' },
		5: { certificate: orange, name: 'orange', description: 'Everyone knows that oranges can be tasty. But you have 50% chance that it will not be good. As your campaigns. You need some more practice.' },
		6: { certificate: lemon, name: 'lemon', description: 'Lemons have many uses. As you have lots of knowledge but more practice is needed.' },
		7: { certificate: cherries, name: 'cherries', description: 'Lots of uses but in overall it\'s sour. Like your unpolished yet skills.' },
		8: { certificate: melon, name: 'melon', description: 'It\'s highly unlikely to have untasty melon salad. But they need additional ingredients. Just like your marketing. It\'s fine but can be better.' },
		9: { certificate: pineapple, name: 'pineapple', description: 'Pineapples are good and tasty but at some point you don\'t want to eat it anymore. Like your marketting. It\'s fine but not for long campaigns. You can improve it!' },
		10: { certificate: grape, name: 'grape', description: 'Oh. wine, raisins and the fruit itself. Lots of uses just like your marketing skills. But remember, there is something that can be improved.' },
		11: { certificate: peach, name: 'peach', description: 'Looks great, tastes very good. It is just not he fruit that you can eat everyday. Your skills are not suitable for each situation. You can improve them.' },
		12: { certificate: kiwi, name: 'kiwi', description: 'Tastes very good but you just can\'t eat lots of it. Like your marketing which is good but not very good.' },
		13: { certificate: watermelon, name: 'watermelon', description: 'Tastes good, smells good, you can prepare it in lots of ways but it is not something that you choose everyday. As your skills that can be improved.' },
		14: { certificate: pear, name: 'pear', description: 'For lots of people it tastes better than apple but still apples win the battle. No one knows why. Some additional knowledge would be beneficial for you.' },
		15: { certificate: raspberry, name: 'raspberry', description: 'Taste 10/10 but it has seeds that are annoying. Like your marketing. Overall is quite good but there are some places to bo done better.' },
		16: { certificate: strawberry, name: 'strawberry', description: 'For lots of people it\'s THE BEST although, as your marketing, for others it can be better.' },
		17: { certificate: apple, name: 'apple', description: 'Is there anyone that does not like apples? Like there is anyone who do not appreciate your skills?' },
		18: { certificate: banana, name: 'banana', description: 'Eveeryone likes bananas and it\'s exactly like your marketing!' },
	};

	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [showScore, setShowScore] = useState(false);
	const [score, setScore] = useState(0);
	const [onHelloPage, setOnHelloPage] = useState(1);

	const handleAnswerOptionClick = (points) => {
		if (window.dataLayer.length) {
			window.dataLayer.push({ 'event': 'button' + currentQuestion + '-click' });
		}
		if (typeof window.fbq != 'undefined') {
			window.fbq('trackCustom', 'step_' + currentQuestion);
		}
		setScore(score + points);

		const nextQuestion = currentQuestion + 1;
		if (nextQuestion < questions.length) {
			setCurrentQuestion(nextQuestion);
		} else {
			setShowScore(true);
		}
		window.document.body.click();
	};
	if (onHelloPage) {
		return <div className='app'>
			<Hello onClick={(e) => {
				e.preventDefault();
				if (typeof window.fbq != 'undefined') {
					window.fbq('trackCustom', 'started_quiz');
				}
				setOnHelloPage(0);
			}}></Hello>
		</div>;
	}
	return (
		<>
			<Sky
				images={{
					/* FORMAT AS FOLLOWS */
					0: f1,
					1: f2,
					2: f3,
					3: f4,
					4: f5,
				}}
				how={130} /* Pass the number of images Sky will render chosing randomly */
				time={60} /* time of animation */
				size={'100px'} /* size of the rendered images */
				background={'palettedvioletred'} /* color of background */
			/>
			<div className='app'>
				<Confetti active={showScore}
					config={{
						angle: 90,
						spread: 360,
						startVelocity: 40,
						elementCount: 100,
						dragFriction: 0.12,
						duration: 5000,
						stagger: 3,
						width: "10px",
						height: "10px",
						perspective: "500px",
						colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
					}}
				/>
				{showScore ? (
					<div className='score-section'>
						Our advanced A.I. says that you are a {scores[score].name} marketter 
						{/* ({parseInt(score / 18 * 100)}%) */}
						.<br /><br />
						{scores[score].description}<br /><br />
						<div
							id="gift-button"
							style={{
								backgroundColor: 'orange',
								color: '#fff',
								padding: '10px',
								borderRadius: '20px',
								cursor: 'pointer',
								boxShadow: 'rgba(255, 255, 255, 0.75) 2px 2px 2px 0px',
								textAlign: 'center',
							}}
							onClick={() => {
								if (typeof window.fbq != 'undefined') {
									window.fbq('trackCustom', 'gone_to_affiliate');
								}
								window.location.href = 'http://mabiki.precmedia.hop.clickbank.net/';
							}}
						>
							I've got something for you {scores[score].name} marketter (click{'\u00A0'}here)
					</div>
						<br />
					Here is your certificate:<br /><br />
						<img style={{
							width: '90%',
							margin: '0 auto',
							display: 'flex',
						}}
							alt="Fruit marketer certificate"
							id="certificate"
							src={scores[score].certificate}></img>



						<br />
						<FacebookShareButton style={{
							marginTop: '20px',
							marginBottom: '20px',
						}}
							url={theImageUrl(scores[score].certificate)}
							quote={"I'm a " + scores[score].name + " marketter. Check your own score at http://quiz.polishprogrammer.com/"} >
							Share your score  on <FacebookIcon style={{
								borderRadius: '50%',
								marginLeft: '20px',
							}} />
						</FacebookShareButton>
					</div>
				) : (
						<>
							<div className='question-section'>
								<div className='question-count'>
									<span>Question {currentQuestion + 1}</span>/{questions.length}
								</div>
								<div className='question-text'>{questions[currentQuestion].questionText}</div>
							</div>
							<div className='answer-section'>
								{questions[currentQuestion].notShuffle
									? questions[currentQuestion].answerOptions.map((answerOption, id) => (
										<button key={window.redraw++} onClick={() => handleAnswerOptionClick(answerOption.points)}>{answerOption.answerText}</button>
									))
									: questions[currentQuestion].answerOptions.sort((node) => node.alwaysLast ? 1 : Math.random() - 0.5).map((answerOption, id) => (
										<button key={window.redraw++} onClick={() => handleAnswerOptionClick(answerOption.points)}>{answerOption.answerText}</button>
									))}
							</div>
						</>
					)}
			</div>
		</>
	);
}
