import React from 'react';
import helloImage from './../assets/hello_image.png';
export default class Hello extends React.Component {
    render() {
        return <div>
            <img 
            style={{
                maxWidth: '100%',
                // marginLeft: '5%',
                borderRadius: '20px'
            }}
            alt="hello image" src={helloImage} ></img>
            <h2>
                Ready for 5 questions that will reveal which fruit in online marketing are you?
                </h2>
            <p
            style={{
                paddingTop: '30px',
                textAlign: 'center',
            }}>
                <a style={{
                    backgroundColor: '#fff',
                    color: '#252d4a',
                    textDecoration: 'none',
                    padding: '10px 30px',
                    borderRadius: '10px',
                }} href="#" onClick={this.props.onClick}>START!</a>
            </p>

        </div>;
    }
}